import { Maybe, SanityCategory } from "@graphql-types";
import { useEffect, useRef } from "react";

import { ActionCreator } from "re-reduced";

import moment from "moment";
import { toast } from "react-toastify";
import { useFirebaseSetDoc } from "./firebase";

interface SaveRecipeProps {
  ids: string[] | null;
  savedRecipes: string[];
  setSavedRecipes: ActionCreator<string[]>;
  userUId?: string;
  isUserSignedIn: boolean | undefined;
  toggleAuthDrawerVisible: ActionCreator<boolean, any>;
}

export function shuffleArray(array: any[], toSlice?: number) {
  const shuffled = array
    .map(a => ({ sort: Math.random(), value: a }))
    .sort((a, b) => a.sort - b.sort)
    .map(a => a.value);

  if (toSlice) {
    return shuffled.slice(0, toSlice);
  }

  return shuffled;
}

export function minsToHrsMins(mins: number) {
  var hours = Math.trunc(mins / 60);
  var minutes = mins % 60;
  return { hours, minutes };
}

export function formatTimeString(hours: number, minutes: number) {
  if (hours) {
    if (minutes > 0) {
      if (hours === 1) return `${hours} hr ${minutes} mins`;
      return `${hours} hrs ${minutes} mins`;
    }

    if (hours === 1) return `${hours} hr`;
    return `${hours} hrs`;
  }

  return `${minutes} mins`;
}

export function daysAgoAsFormattedString(seconds: number) {
  const startDate = moment.unix(seconds);
  const now = moment();

  const diff = now.diff(startDate, "days");

  if (diff === 0) {
    return "Less than a day ago";
  }

  if (diff > 1) {
    return `${diff} days ago`;
  }

  return `A day ago`;
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export const throttle = <T extends (...args: any[]) => any>(
  fn: T,
  delay: number
) => {
  let lastExecuted = 0;

  const throttled = (...args: any[]) => {
    const elapsed = Date.now() - lastExecuted;

    if (elapsed >= delay) {
      lastExecuted = Date.now();
      return fn(...args);
    }
  };

  return throttled as T;
};

export function debounce(callback: any, wait: number) {
  let timeout: any = null;
  // @ts-ignore
  return (...args) => {
    const next = () => callback(...args);
    clearTimeout(timeout);
    timeout = setTimeout(next, wait);
  };
}

export function generateRandomNumber() {
  return Math.floor(Math.random() * 10000);
}

export const handleSaveBulkRecipes = async (props: SaveRecipeProps) => {
  if (!props.isUserSignedIn) {
    props.toggleAuthDrawerVisible(true);
    return;
  }

  const { ids, savedRecipes, setSavedRecipes, userUId } = props;
  if (ids == null || ids.length == 0) {
    return;
  }
  const savedIds =
    savedRecipes && savedRecipes.length > 0
      ? [...new Set([...savedRecipes, ...ids])]
      : ids;

  try {
    await useFirebaseSetDoc("users", userUId, { savedRecipes: savedIds });
    setSavedRecipes(savedIds);
    toast.success("Recipes added to your Favourites", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  } catch (error) {
    console.log("Error saving recipe", error);
    toast.success(`Error saving recipe ${error}`, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  }
};

export function loadWhiskScript(callback: () => void) {
  const existingScript = document.getElementById("whiskScript");
  if (!existingScript) {
    const script = document.createElement("script");
    script.id = "whiskScript";
    script.src = "https://cdn.whisk.com/sdk/shopping-list.js";
    document.head.appendChild(script);
    script.onload = () => {
      if (callback) callback();
    };
  }
  if (existingScript && callback) callback();
}

export const loadScript = (url: string, id: string, callback: () => void) => {
  if (!document) return;

  const tag = document.getElementsByTagName("script")[0];
  const script = document.createElement("script");

  script.type = "text/javascript";
  script.src = url;
  script.id = id;
  script.async = true;
  script.onload = () => callback();
  script.onerror = msg => console.log("error", msg);
  script.onabort = msg => console.log("abort", msg);

  if (tag.parentNode != null) {
    tag.parentNode.insertBefore(script, tag);
  }
};

export function checkForCategory(
  categorySlug: string,
  categories?: Maybe<Maybe<SanityCategory>[]> | string[] | undefined
) {
  if (categories == null) return false;
  if (typeof categories[0] === "string") {
    return (categories as string[]).some(
      category => category.toLowerCase() === categorySlug
    );
  }

  return (categories as SanityCategory[]).some(
    category => category?.slug?.current === categorySlug
  );
}

export function checkForCategoryBadge(
  categories?: Maybe<Maybe<SanityCategory>[]> | string[] | undefined
) {
  if (categories == null) return false;
  const filter = (categories as SanityCategory[]).filter(
    category => category?.recipeBadge
  );

  if (filter.length > 0) {
    return filter;
  }

  return null;
}

export const isBrowser = () => typeof window !== "undefined";

export const addSendInBlueContact = async (body: string) => {
  if (body == null) return null;

  const res = await fetch(`/.netlify/functions/footerSignup`, {
    method: "POST",
    body: body,
  });
  try {
    const response = await res.json();

    if (response) {
      toast.success("Subscribed successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      return response;
    }

    if (response.error) {
      toast.error(response.error);

      return response.error;
    }
  } catch (err) {
    throw err;
  }
};

export function updateOldSearchUrl(oldUrl: string) {
  if (!isBrowser()) return null;
  if (!oldUrl?.includes("#")) return oldUrl;

  let url;
  if (oldUrl.startsWith("http")) {
    // Absolute URL
    url = new URL(oldUrl);
  } else {
    // Relative URL
    url = new URL(oldUrl, window.location.origin);
  }

  const query = url.hash.replace("#", "");

  const newUrl = `${url.origin}/search/?q=${query}`;
  return newUrl;
}
